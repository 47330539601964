import { metrics } from '@news-mono/component-library'
import { layout } from '../../App.routing'
import { colorsPN } from '@news-mono/design-tokens'
import { getHomepagePromotion } from './promotion-cards'

/**
 * A list of top stories(excluding the 1st one) that sits in the left column on desktop and right below the 1st article on tablet/mobile view
 *
 * @param position Position of the segment, i.e. left column or in the middle under the main story
 * @returns ComponentInformation
 */
const getRightNowSegment = (position: 'left' | 'middle') =>
    layout.component({
        type: 'romeo',
        props: {
            removeHorizontalGutters: true,
            hideByline: false,
            hideCardFooter: true,
            topicLevel: 'parent',
            sectionHeader: {
                heading: 'Right',
                hasHeaderLogo: true,
            },
            dataDefinitionArgs: {
                type: 'curation',
                name: 'top-stories',
                offset: 1,
                pageSize: 7,
            },
            displayOnBreakpoint: {
                breakpoint: 'sm',
                inverse: position === 'left',
            },
            imageMode: 'none',
            kickerMode: 'none',
            teaserMode: {
                initial: 'visible',
                lg: 'hidden',
            },
            cardSpacing: 8,
            fontScale: '2XS',
            teaserFontScale: 'L',
            horizontalDividerColor: colorsPN.stroke.weak,
            marginTop: position === 'middle' ? 32 : 0,
            marginBottom: position === 'middle' ? 0 : undefined,
            headerSectionTag: 'h4',
            headerFontOverride: {
                mobile: '2XS',
                tablet: 'XS',
                desktop: 'XS',
            },
        },
    })

export const topStoriesSection = () => {
    const topic = 'top-stories'

    return [
        layout.composition({
            type: 'pn-triton',
            props: {
                isTopSection: true,
            },
            contentAreas: {
                left: [getRightNowSegment('left')],
                main: [
                    layout.component({
                        type: 'pn-article-card',
                        props: {
                            disableImageLazyLoad: true,
                            mediaMode: 'edgeToEdge',
                            teaserMode: 'visible',
                            headerFontScale: 'L',
                            kickerMode: 'top',
                            hideFooter: false,
                            canPlayVideoInline: false,
                            hasBackground: true,
                            fixedRatio: ['4:3', '16:9'],
                            cardType: 'vertical',
                            cardNumber: 1,
                            showAuthor: true,
                            showLiveBlogMilestones: true,
                            dataDefinitionArgs: {
                                type: 'curation',
                                name: topic,
                                offset: 0,
                                pageSize: 1,
                            },
                        },
                    }),
                    getRightNowSegment('middle'),
                    layout.component({
                        type: 'romeo',
                        props: {
                            removeHorizontalGutters: true,
                            hideByline: false,
                            topicLevel: 'parent',
                            teaserMode: 'visible',
                            dataDefinitionArgs: {
                                type: 'curation',
                                name: topic,
                                offset: 8,
                                pageSize: 3,
                            },
                            hideCardFooter: false,
                            hideTopDivider: false,
                            showAuthor: true,
                            cardSpacing: 32,
                            fontScale: 'M',
                            headerFontScale: 'M',
                            teaserFontScale: 'XL',
                            horizontalDividerColor: colorsPN.stroke.weak,
                        },
                    }),
                ],
                right: [
                    layout.component({
                        type: 'ad-unit',
                        props: {
                            padding: [0, 0, metrics.perthnow.margins.md, 0],
                            noticePosition: 'below-center',
                            slot: {
                                id: `${topic}-sidebar-one`,
                                size: 'mrecAbove1280LeaderboardMrecAbove768MrecBelow',
                            },
                            adType: 'inline',
                        },
                    }),
                    layout.component({
                        type: 'romeo',
                        props: {
                            removeHorizontalGutters: true,
                            hideByline: false,
                            topicLevel: 'parent',
                            sectionHeader: {
                                heading: 'Trending',
                                hasHeaderLogo: true,
                            },
                            dataDefinitionArgs: {
                                type: 'curation',
                                name: `trending-${topic}`,
                                offset: 9,
                                pageSize: 5,
                                excludeCurations: [topic],
                            },
                            cardSpacing: 20,
                            fontScale: 'XS',
                            hideCardFooter: true,
                            showCardTopic: true,
                            headerSectionTag: 'h4',
                            centreOnTablet: true,
                            imageSizeOverride: {
                                mobile: '2XS',
                                tablet: '2XS',
                                desktop: '2XS',
                            },
                            headerFontOverride: {
                                mobile: 'XS',
                                tablet: 'XS',
                                desktop: 'XS',
                            },
                        },
                    }),
                    getHomepagePromotion(1),
                ],
            },
        }),
    ]
}
