import { metrics } from '@news-mono/component-library'
import { layout } from '../../App.routing'
import { navLinks } from './home-nav-links'
import { colorsPN } from '@news-mono/design-tokens'
import { TopicSectionParams } from './ngn-home-layout'
import { CheckedComponentInformation } from 'json-react-layouts'

export const dailySection = ({
    topic,
    curation = topic,
    automatedCuration = curation,
    sectionTitle = topic,
    headingUrl,
    navLinkSection,
    taxonomyVersion = 'original',
    afterSidebarComponent,
}: TopicSectionParams) => {
    const sidebar: CheckedComponentInformation[] = [
        layout.component({
            type: 'ad-unit',
            props: {
                padding: [0, 0, metrics.perthnow.margins.md, 0],
                noticePosition: 'below-center',
                slot: {
                    id: `${topic}-sidebar-one`,
                    size: 'mrecAbove1280LeaderboardMrecAbove768NoneBelow',
                },
                adType: 'inline',
            },
        }),
        layout.component({
            type: 'romeo',
            props: {
                removeHorizontalGutters: true,
                hideByline: false,
                topicLevel: 'parent',
                sectionHeader: {
                    heading: 'Trending',
                    hasHeaderLogo: true,
                },
                dataDefinitionArgs: {
                    type: 'curation',
                    name: `trending-${automatedCuration}`,
                    offset: 0,
                    pageSize: 5,
                    excludeCurations: [curation],
                },
                cardSpacing: 20,
                fontScale: 'XS',
                hideCardFooter: true,
                showCardTopic: true,
                centreOnTablet: true,
                imageSizeOverride: {
                    mobile: '2XS',
                    tablet: '2XS',
                    desktop: '2XS',
                },
                headerFontOverride: {
                    mobile: 'XS',
                    tablet: 'XS',
                    desktop: 'XS',
                },
            },
        }),
    ]

    if (afterSidebarComponent) {
        sidebar.push(...afterSidebarComponent)
    }

    return [
        layout.composition({
            type: 'pn-triton',
            props: {
                heading: {
                    heading: sectionTitle,
                    headingUrl: headingUrl,
                    mainSection: true,
                    navLinks:
                        navLinkSection &&
                        navLinks[taxonomyVersion][navLinkSection],
                },
            },
            contentAreas: {
                left: [
                    layout.component({
                        type: 'romeo',
                        props: {
                            removeHorizontalGutters: true,
                            hideByline: false,
                            hideCardFooter: true,
                            topicLevel: 'parent',
                            sectionHeader: {
                                heading: 'Latest',
                            },
                            dataDefinitionArgs: {
                                type: 'listing',
                                topics: [topic],
                                publicationKind: 'article',
                                paging: {
                                    page: 1,
                                    pageSize: 7,
                                },
                            },
                            imageMode: 'none',
                            kickerMode: 'none',
                            teaserMode: {
                                initial: 'visible',
                                lg: 'hidden',
                            },
                            cardSpacing: 8,
                            fontScale: '2XS',
                            teaserFontScale: 'L',
                            horizontalDividerColor: colorsPN.stroke.weak,
                            headerSectionTag: 'h4',
                            headerFontOverride: {
                                mobile: '2XS',
                                tablet: 'XS',
                                desktop: 'XS',
                            },
                        },
                    }),
                ],
                main: [
                    layout.component({
                        type: 'pn-article-card',
                        props: {
                            disableImageLazyLoad: true,
                            mediaMode: 'edgeToEdge',
                            headerFontScale: 'L',
                            teaserMode: 'visible',
                            hideFooter: false,
                            canPlayVideoInline: false,
                            hasBackground: true,
                            fixedRatio: ['4:3', '16:9'],
                            cardType: 'vertical',
                            cardNumber: 1,
                            kickerMode: 'top',
                            showAuthor: true,
                            showLiveBlogMilestones: true,
                            dataDefinitionArgs: {
                                type: 'curation',
                                name: curation,
                                offset: 0,
                                pageSize: 1,
                            },
                        },
                    }),
                    layout.component({
                        type: 'romeo',
                        props: {
                            removeHorizontalGutters: true,
                            hideByline: false,
                            topicLevel: 'parent',
                            teaserMode: 'visible',
                            dataDefinitionArgs: {
                                type: 'curation',
                                name: curation,
                                offset: 1,
                                pageSize: 3,
                            },
                            hideCardFooter: false,
                            hideTopDivider: false,
                            showAuthor: true,
                            cardSpacing: 32,
                            fontScale: 'M',
                            headerFontScale: 'M',
                            teaserFontScale: 'XL',
                            horizontalDividerColor: colorsPN.stroke.weak,
                        },
                    }),
                ],
                right: sidebar,
            },
        }),
    ]
}
